.footer {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px dotted $color-grey;
  margin-top: 4rem;
  &__link {
    text-decoration: none;
    font-size: 1.2rem;
    color: $color-lightgrey;
  }
} 