.project-card {
  padding: 2rem;
  padding-bottom: 1.5rem;
  margin: 0 auto 2rem;
  border: 2px solid #404040;
  border-radius: 5px;
  max-width: 470px;
  animation: fadeDown 1s ease-out;

  &__flex {
    border-bottom: 1px solid #404040;
  }
  &__img {
    display: block;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 2rem;
    transition: .3s ease;
    border-radius: 5px;

    &:hover {
      opacity: .6;
      transform: translateY(-2px);
    }
  }
  

  &__title {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: $color-primary;
  }

  &__description {
    text-align: center;
    line-height: 1.3;
    margin: 0 auto 3rem;
    max-width: 300px;
  }

  &__links-title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    border-top: 1px dotted #404040;
  }
  
  &__links {
    padding-top: 1rem;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link {
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
    transition: 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
      transform: translateY(-2px);
    }
  }

}

@media screen and (min-width: 768px) {
  .project-card {
    max-width: 900px;
    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 2rem;
      min-height: 240px;
    }

    &__right {
      border-left: 1px dotted #404040;
      width: 70%;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__left {
      padding-right: 2rem;
    }
    &__img {
      margin: 0;
    }

    &__links {
      max-width: 500px;
    }

    
    &__description {
      margin: 0 auto;
    }
  }
}
