.hero {
  height: 60vh;
  min-height: 470px;
  padding-top: 15%;
  position: relative;
  &__title-container {
    overflow: hidden;
    margin-bottom: 1.5rem;
  }

  &__title {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 4.5rem;
    font-weight: normal;
    opacity: 0;
    animation: fadeUp 1s ease-out forwards;

    &--first-enter {
      animation-delay: 2s;
    }

    @media screen and (min-width: 768px) {
      font-size: 5rem;
    }
    @media screen and (max-width: 300px) {
      font-size: 3.5rem;
    }
  }

  &__subtitle {
    max-width: 280px;
    font-size: 1rem;
    line-height: 1.3;
    letter-spacing: 0.2px;
    font-weight: normal;

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
    }
    span {
      display: block;
      margin-bottom: 0.7rem;
      opacity: 0;
      animation: fadeDown 1s ease-in-out forwards;

        &:nth-child(2) {
          animation-delay: .2s;
        }

        &:nth-child(3) {
          animation-delay: .4s;
        }
    }
    &--first-enter {
      span {

        &:first-child {
          animation-delay: 2.6s;
        }

        &:nth-child(2) {
          animation-delay: 2.8s;
        }

        &:nth-child(3) {
          animation-delay: 3s;
        }
      }
    }
  }

  &__link {
    color: $color-primary;
    text-decoration: none;
    transition: 0.2s ease-in-out;

    &:hover {
      opacity: 0.75;
    }
  }
}
