.skills {
  min-height: calc(100vh - 284px);
  width: 100%;
  padding: 1rem;
  font-family: 'Josefin Sans', sans-serif;

  &__title {
    text-transform: uppercase;
    color: $color-grey;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 7rem;
    margin-top: 5rem;
    opacity: 0;
    animation: fadeUp 1s ease-out forwards .2s;
  }

  &__container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px,1fr));
    grid-column-gap: 4rem;
    grid-row-gap: 6rem;
    
  }
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100px;

  &__name {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
}