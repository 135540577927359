.contact {
  margin: 1rem;
  margin-bottom: 4rem;
  min-height: calc(100vh - 370px);

  &__title {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    color: $color-grey;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 5rem;
    opacity: 0;
    animation: fadeUp 1s ease-out forwards .2s;
  }
}



.contact-form {
  color: $color-lightgrey;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  

  &__form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    position: relative;
  }

  &__label {
    // font-weight: 700;
    font-size: .8rem;
    text-transform: capitalize;
    margin-bottom: 0.1rem;
  }

  &__form-control {
    padding: 0.6rem;
    border: 2px solid lighten($color-lightgrey, 50%);
    border-radius: 10px;
    background: lighten($color-lightgrey, 60%);
    
    &:focus {
      outline: none;
      border-color: $color-lightgrey;
      box-shadow: 0 0 4px lighten($color-lightgrey, 50%);
    }

    &--message {
      resize: none;
      height: 220px;
    }

    &--error {
      border-color: rgb(156, 152, 152);
      background: rgb(156, 152, 152);
      transition: .1s;
    }
  }

  &__submit-btn {
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid $color-primary;
    font-family: 'Raleway', sans-serif;
    color: white;
    background: $color-primary;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    transition: .3s ease-out;
    width: 50%;
    margin: .5rem auto 0;
    font-size: 1rem;
    
    &:hover,
    &:active {
      outline: none;
      color: $color-primary;
      background: transparent;
    }
  }

  &__error-msg {
    position: absolute;
    bottom: -.9rem;
    font-size: 0.7rem;
    color: #fdfaff;
    right: 0;
    animation: fadeIn .1s;
  }
}
