@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(50px)
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-20px)
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
