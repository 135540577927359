* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #000;
  color: #fff;
  font-family: 'Quattrocento Sans', sans-serif;
  -webkit-tap-highlight-color: transparent; 
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(245, 245, 245, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(245, 245, 245, 0.3);
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px $color-primary;
  -webkit-box-shadow: inset 0 0 6px $color-primary;;
}