.work {
  padding-top: 2rem;

  &__title {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 4rem;
    color: $color-grey;
    text-transform: uppercase;
    text-align: right;
    border-bottom: 2px solid $color-darkgrey;
    max-width: 250px;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: 1rem;

    @media screen and (min-width: 576px){
      font-size: 6rem;
      margin-right: 2rem;
      max-width: 400px;
    }

    @media screen and (min-width: 768px){
      font-size: 6.5rem;
      margin-right: 4rem;
      max-width: 500px;
    }
  }
}