.social-links {
  position: absolute;
  bottom: 30px;
  right: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 7rem;

  @media screen and (min-width: 336px){
    height: 8rem;
  }

  a {
    padding: 5px 0;
    &:hover  img {
      opacity: .6;
      transform: translateY(-2px);
    }
  }

  img {
    width: 1.3rem;
    transition: .3s ease;
  }
}