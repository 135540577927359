.message-sent-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-darkgrey;
  animation: fadeIn .2s;

  &__box {
    // background: white;
    // border: 1px solid lighten($color-lightgrey, 40%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 2rem;
  }

  &__title {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.5rem;
    color: lighten($color-grey, 5%);
    text-transform: uppercase;
  }

  &__subtitle {
    text-align: center;
    color: lighten($color-grey, 40%);
  }

  &__btn {
    margin-top: 2rem;
    text-decoration: none;
    color: $color-primary;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .message-sent-modal {
    &__title {
      font-size: 8rem;
    }

    &__subtitle {
      font-size: 1.5rem;
    }
  }
}