.nav {
  display: none;
  font-family: 'Josefin Sans', sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__list {
    list-style-type: none;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
  }

  &__link {
    text-decoration: none;
    color: #383838;
    font-size: 4rem;
    font-weight: 700;
    text-transform: uppercase;
    transition: .3s ease-in-out;

    @media screen and (min-width: 768px) {
      font-size: 9rem;
    }

    @media screen and (max-width: 320px) {
      font-size: 3.5rem;
    }

    &:hover {
      color: #28A7A4;
    }
  }

  &__main-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-darkgrey;
    z-index: 1;
    overflow: hidden;
  }

  &__secondary-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: darken($color-primary, 20%);
  }
}