.welcome {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  &__primary {
    
    background: $color-darkgrey;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 2;
    height: 100vh;
    width: 100%;
  }

  &__title-container {
    padding: 1rem;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

  }

  &__title {
    font-size: 2rem;
    color: rgb(128, 128, 128);
    font-family: 'Josefin Sans', sans-serif;
    opacity: 0;
    @media screen and (min-width: 768px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 340px) {
      font-size: 1.5rem;
    }
  }

  &__secondary {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(179, 179, 179);
   
    display: flex;
    align-items: center;
    justify-content: center;

  }
}