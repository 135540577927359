.hamburger {
  position: relative;
  width: 3rem;
  height: 2rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  
  z-index: 20;

  &:focus {
    outline: none;
  }

  &__bars {
    width: 2rem;
    height: 1.5rem;
    position: relative;
    overflow: hidden;
    padding: 1.4rem;
  }

  &__bar {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2rem;
    border: 2px solid $color-lightgrey;
    background: $color-lightgrey;;
    transition: 0.6s cubic-bezier(0.8, 1.61, 0.25, 1);
    border-radius: 2px;

    &--top {
      top: 30%;
    }

   
    &--bottom {
      bottom: 30%;
    }
  }

 &--clicked {

    .hamburger__bar {
      border-color: $color-primary;
      background: $color-primary; 
      &--top {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      

      &--bottom {
        bottom: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
        // width: 1.2rem;
      }
    }
  }
}
