.header {
  width: 100%;
  height: 110px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.9)),
    url('../assets/img//bg2.jpg') 70% 10% / cover;
  
  &__logo {
    text-decoration: none;
    color: $color-lightgrey;
    font-size: 1.2rem;
    transition: .3s ease;

    &:hover {
      color: $color-primary;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}
